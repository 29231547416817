.MainPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-container {
  padding: 0px;
  display: flex;
  flex-direction: row;
}

.header {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  padding: 0;
}

.headerImage {
  width: 100%;
}

.sidebar {
  marging: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 20%;
  /* height: 100%; */
  background-color: #009ee3;
  padding: 0px;
}

.sideBarImage {
  width: 50%;
    padding-top: 1rem;
}

.content {
  width: 100%;
  padding: 0px;
}

.contentImage {
  width: 40%;
    padding: 1rem;
    padding-top: 1 rem;
}

.question {
  font-size: 1.5rem;
  width: 96%;
  padding: 10px;
  font-weight: bold;
  color: rgb(4, 4, 86);
}

button {
  display: block;
  margin: 10px;
  padding: 10px;
  background-color: #009ee3;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  width: 96%;
  font-weight: bold;
  color: #ffffff;
}

button:hover {
  background-color: #009ee3;
}

.video-section {
  margin: 0px;
  margin-top: 20px;
  width: 96%;
  text-align: center;
}

.iframe-video {
  margin: 0px;
  text-align: center;
  padding: 10px;
  width: 100%;
  border: 0;
}

.message {
  padding: 10px;
  padding-top: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: justify;
}

/* Ajuste específico para pantallas pequeñas (iPhone SE) */
@media (max-width: 375px) {
  .question {
    font-size: 0.8rem;
  }

  button {
    font-size: 0.8rem;
  }

  .message {
    font-size: 0.8rem;
  }
}