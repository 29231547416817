.video-page {
  margin: 0px;
  margin-top: 20px;
  width: 96%;
  text-align: center;
  padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* Relación de aspecto 16:9 */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin-bottom: 16px;
  /* Ajusta el espacio entre el video y el texto */
}

.video-container .iframe-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  }
  
  @media (max-width: 600px) {
    .video-container {
      padding-bottom: 60%;
      /* Ajuste ligero para pantallas pequeñas */
      margin-bottom: 10vw;
      /* Espacio dinámico */
    }
  }